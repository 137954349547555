import React from 'react'
import './footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {Link} from 'react-router-dom'
const Footer = () => {
  return (
    <div className='footer'>
      <div className="up">
        <h1>Welcome to Lybrra, The Luxury Hub of Fashion</h1>
        <p>Founded in 2021, Lybrraa.com is a premier online shopping platform that delivers exceptional products and customer service.</p>
        <div className="subs">
            <input type="text" placeholder='Subscribe for notifications...'/>
            <button>SUBSCRIBE</button>
        </div>
        <div className="menus">
            <div className="left">
<p>Pages</p>
<ul>
    <Link to="/"><li>Home</li></Link>
    <Link to="/about"><li>About</li></Link>
    <Link to="/contact"><li>Contact</li></Link>
    <Link to="/categories"><li>Categories</li></Link>
    <Link to="/orders"><li>Orders</li></Link>

</ul>
            </div>
            <div className="right">
<p>Collections</p>
<ul>
<Link to="/"><li>Gym T-shirt</li></Link>
<Link to="/"><li>Anime T-shirt</li></Link>
<Link to="/"><li>Oversized T-shirt</li></Link>
</ul>
            </div>
            <div className="right">
<p>Our Policies</p>
<ul>
<Link to="/privacy-policy"><li>Privacy Policy</li></Link>
<Link to="/shipping-policy"><li>Shipping Policy</li></Link>
<Link to="/return-exchange-policy"><li>Return and Exchange</li></Link>
<Link to="/terms-of-service"><li>Terms of Service</li></Link>
</ul>
            </div>
            <div className="social">
<p>Social Links</p>
<ul>
<Link to="/"><li><FacebookIcon className='icon'/></li></Link>
<Link to="/"><li><InstagramIcon className='icon'/></li></Link>
</ul>
            </div>
        </div>
      </div>
      <div className="down">
<p>Copyright 2024 © Lybrraa</p>
<img src="https://content.invisioncic.com/p289038/monthly_2022_10/Payment-methods.png.2b9ba23475aaa15189f555f77ec3a549.png" alt="" />
      </div>
    </div>
  )
}

export default Footer
