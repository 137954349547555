import React, { useContext,useState,useEffect } from 'react'
import './header.css'
import {useLocation, useNavigate,Link} from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { HeaderContext } from '../../context/HeaderContext';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {getAllBanner} from '../../features/banner/bannerSlice'
import {useDispatch,useSelector} from 'react-redux'
import {getAllCollection} from '../../features/collection/collectionSlice'
import {getAllFeatured} from '../../features/products/productSlice'
import {words} from './words'
import Carousel from 'react-bootstrap/Carousel';

const Header = () => {
  const dispatch=useDispatch()
  const collectionState = useSelector((state) => state?.collection?.collection);
  const productState1=useSelector((state)=>state?.product?.featured?.data)
  const bannerState=useSelector((state)=>state?.banner?.banner)
const [slidercon,setSlidercon]=useState("")
  useEffect(()=>{
dispatch(getAllBanner())
dispatch(getAllCollection())
dispatch(getAllFeatured())
const a=JSON.parse(localStorage?.getItem("carousel"))
setSlidercon(a?.carou)
  },[])

  const {headText,cartLength,wishLength}=useContext(HeaderContext)
  const location=useLocation()
  const navigate=useNavigate()
  const [scrolled, setScrolled] = useState(false);
const [searchShow,setSearchShow]=useState(false)
const [headShow,setHeadShow]=useState("-100%")
const cart=JSON.parse(localStorage.getItem("cart"))
  const wishlist=JSON.parse(localStorage.getItem("wishlist"))
const searchOpen=()=>{
  setSearchShow(true)
}
const searchClose=()=>{
  setSearchShow(false)
}
const headOpen=()=>{
  setHeadShow("0")
}
const headClose=()=>{
  setHeadShow("-100%")
}
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

const [svalue,setSvalue]=useState("")
const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    localStorage.setItem("search",JSON.stringify({
      mysearch:svalue
      
    }))
   
      navigate(`/products`)
      setSearchShow(false)
      setSvalue("")
  }
  
};
const searchValueClick = () => {
  localStorage.setItem("search",JSON.stringify({
    mysearch:svalue
    
  }))
 
    navigate(`/products`)
    setSearchShow(false)
    setSvalue("")

};

const [results, setResults] = useState([]);
useEffect(() => {
  if (svalue?.length > 1) {
    // Filter the words that contain the input value
    const filteredWords = words
      .filter((item) => item?.name?.toLowerCase()?.includes(svalue?.toLowerCase()))
      .slice(0, 5);

    setResults(filteredWords); // Store the filtered words in state
  } else {
    setResults([]); // Clear results when input is less than 2 characters
  }
}, [svalue]);

const rawContent =slidercon || ""
const cleanContent = rawContent.replace(/<\/?p>/g, ""); // Removes <p> and </p> tags
const contentArray = cleanContent.split(',').filter(item => item.trim() !== "");
  return (
    <div className='header'>
      <div className="overlay" style={{display:headShow==="0"?"block":"none"}}></div>
      <div className="mobile">
       <div className="header1" style={{display:location.pathname==="/"?"block":"none"}}>
       <div className="info">
        
       <Carousel data-bs-theme="light" indicators={false}>
    {contentArray.map((item, index) => (
        <Carousel.Item className='item' key={index}>
            {item.trim()} {/* Displaying the text directly */}
        </Carousel.Item>
    ))}
</Carousel>
       </div>
       <div className="up">
       <div className="left">
        <MenuIcon className='ham' onClick={(e)=>headOpen()}/>
        </div>
        <div className="head-img">
<Link to="/"><img src={bannerState[4]?.images[0]?.url} alt={bannerState[4]?.alt} /></Link>

        </div>
        <div className="right">
            <div className="wishlist">
            <Link to="/wishlist"><FavoriteBorderIcon className='head-icon'/><span style={{display:wishLength>0?"flex":"none"}}>{wishLength}</span></Link>
            </div>
            <div className="cart">
            <Link to="/cart"><ShoppingBagOutlinedIcon className='head-icon'/><span style={{display:cartLength>0?"flex":"none"}}>{cartLength}</span></Link>
            </div>
        </div>
       </div>
       <div className="down" onClick={searchOpen}>
            <SearchIcon className='icon'/>
            <p>Search For Products...</p>
        </div>

       </div>
       <div className="header2" style={{display:location.pathname==="/"?"none":"flex"}}>
        <div className="left" onClick={(e)=>navigate("/")}>
<KeyboardBackspaceOutlinedIcon className='back-icon'/>
<p>{headText}</p>
        </div>
        <div className="right">
        <div className="wishlist">
<Link to="/wishlist"><FavoriteBorderIcon className='head-icon'/><span style={{display:wishLength>0?"flex":"none"}}>{wishLength}</span></Link>
            </div>
            <div className="cart">
<Link to="/cart"><ShoppingBagOutlinedIcon className='head-icon'/><span style={{display:cartLength>0?"flex":"none"}}>{cartLength}</span></Link>
            </div>
        </div>
       </div>
       <div className={`header3`}>
       <div className="info">
       <Carousel data-bs-theme="light" indicators={false}>
    {contentArray.map((item, index) => (
      <Carousel.Item className='item' key={index}>
        {item.trim()} {/* Displaying the text directly */}
      </Carousel.Item>
    ))}
  </Carousel>
       </div>
        <div className="grids">
        <div className="left">
        <Link to="/"><img src={bannerState[4]?.images[0]?.url} alt={bannerState[4]?.alt} /></Link>
        </div>
        <div className="right">
            <ul>
            <Link to="/"><li>Home</li></Link>
            <Link to="/collections"><li>Collections</li></Link>
            {
            collectionState && collectionState?.map((item,index)=>{
              return <Link key={index} to={`/collection/products/${item?.handle}`} onClick={(e)=>headClose()}><li>{item?.category}</li></Link>
            })
          }
            </ul>
            
        </div>
        <div className="center">
          <div onClick={searchOpen}>
          <SearchIcon/>
          </div>
          <div className="wishlist">
            <Link to="/wishlist"><FavoriteBorderIcon className='head-icon'/><span style={{display:wishLength>0?"flex":"none"}}>{wishLength}</span></Link>
            </div>
            <div className="cart">
            <Link to="/cart"><ShoppingBagOutlinedIcon className='head-icon'/><span style={{display:cartLength>0?"flex":"none"}}>{cartLength}</span></Link>
            </div>
        <button onClick={()=>navigate("/orders")}>Orders</button>
        </div>
        </div>
       </div>
       <div className="search-head" style={{top:searchShow?"0":"-120%"}}>
        <CloseIcon className='close' onClick={searchClose}/>
        <div className="search-bar">
<input type="text" placeholder='Search for products....' value={svalue} onChange={(e)=>setSvalue(e.target.value)} onKeyDown={handleKeyDown}/>
<button onClick={searchValueClick}>Search</button>
        </div>
        <div className="recent-search">
          <p>Recent Searches</p>
          <ul>
          {
            results?.map((item,index)=>{
              return <li onClick={(e)=>setSvalue(item?.value)}>
              <p><SearchIcon className='icons'/></p>
              <p >{item?.name}</p>
            </li>
            })
          }
            
          </ul>
        </div>
        <div className="trending-collections">
        <p className="trending-heads">
          Explore Trending Products
        </p>
        <div className="trend-list">
          {
            productState1 && productState1?.slice(0,4)?.map((item,index)=>{
              return <Link to={`/product/${item?.handle}`}>
              <div className="prdt">
              <img src={item?.images[0]?.url} alt="" />
              <p className='name'>{item?.title}</p>
              <p className="price">Rs. {item?.price}</p>
            </div>
              </Link>
            })
          }
          
          
        </div>
      </div>
       </div>
       <div className="toggle-head" style={{left:headShow}}>
        <div className="close" onClick={(e)=>headClose()}>
        <CloseIcon className='ham-close'/>
        </div>
        <ul>
          <Link to="/" onClick={(e)=>headClose()}><li>Home</li></Link>
          {
            collectionState && collectionState?.map((item,index)=>{
              return <Link key={index} to={`/collection/products/${item?.handle}`} onClick={(e)=>headClose()}><li>{item?.category}</li></Link>
            })
          }
          <Link to="/orders" onClick={(e)=>headClose()}><li>Orders</li></Link>
          <Link to="/blogs" onClick={(e)=>headClose()}><li>Blogs</li></Link>
          <Link to="/about" onClick={(e)=>headClose()}><li>About</li></Link>
          <Link to="/contact" onClick={(e)=>headClose()}><li>Contact</li></Link>
        </ul>
       </div>
      </div>
    </div>
  )
}

export default Header
